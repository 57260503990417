import ReactDOM from "react-dom";
import {BundleEntry} from "./components/bundle-entry/BundleEntry";
import HomeBrands from "./components/HomeBrands/HomeBrands";
import React from "react";
import {getBlLocaleFromString} from "./lib/BlLocale";

const target = document.getElementById('home-brands');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);

if (target) {
	ReactDOM.render(
		<BundleEntry
			bundleName="home-brands"
			locale={localeCode}
			prefetchTranslations={[
				'frontend.home.popularBrands',
				'category.goToAll'
			]}>
			<HomeBrands/>
		</BundleEntry>,
		document.getElementById('home-brands')
	);
}

import {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {getTopBrands} from "../../lib/api/brandsApi";
import {BrandModel} from "./models/BrandModel";
import {useEmblaCarousel} from "embla-carousel/react";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";

export default function HomeBrands() {
	const [isLoading, setIsLoading] = useState(true);

	const [allBrands, setAllBrands] = useState<BrandModel[]>();
	const isMobile = !useMediaQuery(mediaQueries.sm);

	const [emblaRef] = useEmblaCarousel({align: "start", containScroll: "trimSnaps", dragFree: true});

	useEffect(() => {
		getTopBrands()
			.then(brandResult => {
				setAllBrands(brandResult);
				setIsLoading(false);
			});
	}, []);

	if (isLoading || !allBrands) {
		return (
			<div className={"tw-bg-skeletonBackgroundGrey embla-home-slider__container tw-flex tw-mb-16"}/>
		);
	}

	if (!isMobile) {
		return (
			<div>
				<div className={'tw-flex tw-flex-row tw-justify-between tw-items-end tw-mb-12'}>
					<a href={'/marken'}
					   className={'tw-text-4xl md:tw-text-sliderSubTitle tw-black tw-font-bold tw-font-gotham-black'}>
						<FormattedMessage id={'frontend.home.popularBrands'}/>
					</a>
					<a className={'tw-black tw-font-gotham tw-flex tw-items-end hover:tw-text-grey hover:tw-underline'}
					   href={'/marken'}>
						<FormattedMessage id={'category.goToAll'}/>
					</a>
				</div>

				<div id={'home-brands-all'} className={'tw-flex tw-flex-row tw-w-full tw-space-x-7'}>
					{allBrands && allBrands.map(brand =>
						<div key={brand.id}
							 className={'tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-96 tw-border-homeBrandsBorderWidth tw-rounded-md tw-border-homeBrandsBorder'}>
							<a href={brand.link}>
								<img className={'img tw-w-full'}
									 src={brand.image}
									 alt={brand.name}/>
							</a>
						</div>
					)}
				</div>
			</div>
		)

	} else {
		return (
			<div className={'embla'}>
				<div className={"embla__viewport is-draggable"} ref={emblaRef}>
					<div className="embla__container tw-space-x-7 tw-w-64 tw-h-64">
						{allBrands && allBrands.map(brand =>
							<div className={'embla__slide'}
								 key={brand.id}>
								<div
									className="tw-border-homeBrandsBorderWidth tw-rounded-md tw-border-homeBrandsBorder tw-h-full">
									<a href={brand.link}>
										<img className={'tw-p-2 tw-w-full'} src={brand.image}
											 alt={brand.name}/>
									</a>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}
